<template>
  <header class="LayoutHeader">
    <div class="top">
      <div class="monitor-name">
        <a :href="monitor.url"
           class="favicon"
           target="_blank">
          <img :src="monitor.url | favicon" />
        </a>
        <div ref="url"
             class="url truncate ml-2">
          {{ monitor | monitorNameOrUrl }}
        </div>
        <monitor-status class="ml-2"
                        :monitor="monitor"
                        :detailed="false" />
      </div>
      <router-link class="back-link"
                   :to="monitorsRoute">
        <!-- <icon-arrow-left class="icon" /> -->
        <span class="ml-2">← Back to Monitors</span>
      </router-link>
    </div>

    <monitor-navigation :monitor="monitor" />
  </header>
</template>

<script>
import MonitorNavigation from './MonitorHeaderNavigation.vue'
import MonitorStatus from '@/components/Monitor/MonitorStatus.vue'

import tippy from 'tippy.js'

export default {
  components: {
    MonitorNavigation,
    MonitorStatus
  },

  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  mounted () {
    tippy(this.$refs.url, {
      content: this.monitor.url,
      interactive: true
    })
  },

  computed: {
    monitorsRoute () {
      return {
        name: 'monitors.list'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .LayoutHeader {
    margin-bottom: 20px;
    border-bottom: 1px solid #dfe2e4;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      margin-bottom: 10px;
      @media (max-width: 500px) {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
      }
    }

    .monitor-name {
      display: flex;
      align-items: center;

      .favicon {
        @include flex-center;

        // width: 30px;
        // height: 30px;

        // border: 1px solid rgba(0, 0, 0, 0.125);
        // border-radius: 4px;

        // background-color: #fff;

        > img {
          width: 16px;
          height: 16px;
        }
      }

      .url {
        font-size: 24px;
        max-width: 500px;
        font-weight: 600;
        letter-spacing: -.02em;
      }
    }

    .back-link {
      display: flex;
      align-items: center;
      color: lighten(map-get($colors, gray-1), 20%);

      @media (max-width: 500px) {
        order: -1;
        margin-bottom: 5px;
      }

      ::v-deep .icon g {
        fill: lighten(map-get($colors, gray-1), 20%);
      }

      &:hover {
        color: map-get($colors, purple-1);

        ::v-deep .icon g {
          color: red;
          fill: map-get($colors, purple-1);
        }
      }
    }
  }
</style>
